import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import authUser, { types as authTypes } from './authUser';
import crud from './crud';
import registration from './registration';
import notification from './notification';
import transfer from './transfer';

const appReducer = combineReducers({
  toastr: toastrReducer,
  loadingBar: loadingBarReducer,
  authUser,
  crud,
  registration,
  notification,
  transfer,
});

/*
 * Reset state
 * https://stackoverflow.com/a/35641992/220356
 */
const rootReducer = (state, action) => {
  if (action.type === authTypes.LOGOUT) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

import axios from 'axios';
import API_DOMAIN from './apiDomain';
import { store } from '../store';

export async function get(endPoint) {
  const userToken = store.getState().authUser.token;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
  };
  const url = `${API_DOMAIN}/${endPoint}`;
  return axios.get(url, options);
}

export default { get };

import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthenticateRoute from './auth/AuthenticateRoute';
import ErrorBoundary from './ErrorBoundary';
import { ContainerLoader } from '../components/loader';

// lazy loaded components (components should have a default export to lazy load)
const Auth = lazy(() => import('./auth'));
const Home = lazy(() => import('./home'));
const NotFound = lazy(() => import('./notfound'));

const AdminUser = lazy(() => import('./admin-user'));
const Yard = lazy(() => import('./yard'));
const LicenseType = lazy(() => import('./license-type'));
const LectureHall = lazy(() => import('./lecture-hall'));
const TrainingAndTestingRequirement = lazy(() =>
  import('./training-testing-requirement')
);
const FeeCode = lazy(() => import('./fee-code'));
const CountryGroup = lazy(() => import('./country-group'));
const BranchType = lazy(() => import('./branch-type'));
const LowCreditSettings = lazy(() => import('./low-credit-setting'));
const Branch = lazy(() => import('./branch'));
const Instance = lazy(() => import('./instance'));
const DocumentType = lazy(() => import('./document-type'));
const CourseTypes = lazy(() => import('./course-type'));
const CourseTypeIncentive = lazy(() => import('./course-type-incentive'));
const Courses = lazy(() => import('./course'));
const Promotions = lazy(() => import('./promotion'));
const Company = lazy(() => import('./company'));
const ErrorCodeCategory = lazy(() => import('./error-code-category'));
const TrainingTopic = lazy(() => import('./training-topic'));
const ErrorCode = lazy(() => import('./error-code'));
const Instructor = lazy(() => import('./instructor'));
const CsrStudentRegistration = lazy(() => import('./csr-student-registration'));
const StudentRegistration = lazy(() => import('./student-registration'));
const StudentTransfer = lazy(() => import('./student-transfer'));
const StudentRefund = lazy(() => import('./student-refund'));
const CsrLeadQualification = lazy(() => import('./csr-lead-qualification'));
const Student = lazy(() => import('./student'));
const CDDStudentUpdate = lazy(() => import('./cdd-student-update'));
const StudentStage = lazy(() => import('./student-by-stage'));
const LeaveSummary = lazy(() => import('./leave-summary'));
const CsrDropOffRegistration = lazy(() =>
  import('./csr-drop-off-registration')
);
const InstructorPerformanceReview = lazy(() =>
  import('./instructor-performance-review')
);
const UploadInstructorFine = lazy(() => import('./instructor-fines-upload'));
const InstructorPerformanceReviewReport = lazy(() =>
  import('./instructor-performance-review-report')
);
// const InstructorFineReport = lazy(() => import('./instructor-fines-report'));

// Import the component for the new route
const WorkingHoursPracticalTraining = lazy(() => import('./working-hour'));
const WorkingHoursTheory = lazy(() => import('./working-hour-theory'));
const WorkingHoursMockTest = lazy(() => import('./working-hour-mock-test'));
const WorkingHoursSimulatorTraining = lazy(() =>
  import('./working-hour-simulator-training')
);
const LectureHallSchedule = lazy(() => import('./lecture-hall-schedule'));
const LectureTheory = lazy(() => import('./lecture-theory'));
const ApiAccessLogs = lazy(() => import('./api-access-logs'));
const Language = lazy(() => import('./language'));
const RisLanguage = lazy(() => import('./ris-language'));
const TaxClass = lazy(() => import('./tax-class'));
const PaymentReport = lazy(() => import('./payment-report'));
const TrainingInfo = lazy(() => import('./integrator-traininginfo'));
const PaymentClearance = lazy(() => import('./integrator-paymentclearance'));
const CTSStudentProfileInfo = lazy(() => import('./cts-studentprofileinfo'));
const CTSScheduleClass = lazy(() => import('./cts-scheduleclass'));
const RamadanSettings = lazy(() => import('./ramadan-settings'));
const Cashier = lazy(() => import('./cashier'));
const ServiceHeartBeat = lazy(() => import('./service-heart-beat'));
const RPCHeartBeat = lazy(() => import('./rpc-heart-beat'));
const CTSLectureAttendanceInfo = lazy(() =>
  import('./cts-lectureattendanceinfo')
);
const CTSAssessmentBookingInfo = lazy(() =>
  import('./cts-assessmentbookinginfo')
);
const CSRCollectionReport = lazy(() => import('./csr-collection-report'));
const Holiday = lazy(() => import('./holiday'));
const TrainingData = lazy(() => import('./integrator-fetchRtaDI'));
const CTSAssessmentResult = lazy(() => import('./cts-fetcherAssessmentResult'));
const CTSAttendanceResult = lazy(() => import('./cts-fetcherAttendanceResult'));
const CTSInstructorAlert = lazy(() => import('./cts-fetcherinstructoralert'));
const StudentCDD = lazy(() => import('./di-recieverstudentcdd'));
const ExamDetails = lazy(() => import('./di-recieverexamdetails'));
const RescheduleWarning = lazy(() => import('./di-receiverreschedulewarning'));
const IntegratorDashboard = lazy(() => import('./integrator-dashboard'));
const Contract = lazy(() => import('./contract'));
const IntegratorLog = lazy(() => import('./integrator-logs'));
const UploadTrainingInfo = lazy(() => import('./upload-training-info'));
const ChangeInstructorOfSchedules = lazy(() =>
  import('./change-instructor-of-schedules')
);
const ExamResult = lazy(() => import('./di-recieverexamresult'));
const SupervisorDashboard = lazy(() => import('./supervisor-dashboard'));
const SanityLog = lazy(() => import('./cts-sanityLog'));
const TrainingSchedule = lazy(() => import('./training-schedule'));
const BackUpTrainingSchedule = lazy(() =>
  import('./backup-instructor-availability-generator')
);
const BackUpTrainingReport = lazy(() =>
  import('./backup-instructor-schedule-report')
);
const BackUpTrainingNewReport = lazy(() =>
  import('./backup-instructor-schedule-new-report')
);
const CtsStudentPermitDetails = lazy(() =>
  import('./cts-studentpermit-details')
);
const InstructorUtilizationReport = lazy(() =>
  import('./instructor-utilization-report')
);
const BackupInstructorUtilizationReport = lazy(() =>
  import('./backup-instructor-utilization-report')
);
const RegistrationReport = lazy(() => import('./registration-report'));
const TestReport = lazy(() => import('./test-report'));
const MockTestReport = lazy(() => import('./mock-test-report'));
const InstructorApiReport = lazy(() => import('./instructor-api-report'));

const InstructorPassingReport = lazy(() =>
  import('./instructor-passing-report')
);
const InstructorPassingPerformanceReport = lazy(() =>
  import('./instructor-passing-performance-report')
);
const AdditionalTrainingLog = lazy(() => import('./additional-training-log'));
const SmsLog = lazy(() => import('./sms-log'));
const UtilizationLog = lazy(() => import('./utilization-logs'));
const StudentwiseUtilizationLog = lazy(() =>
  import('./studentwise-utilization-log')
);
const DownloadHistory = lazy(() => import('./download-history'));
const CMSEmail = lazy(() => import('./cms-email'));
const CMSNotification = lazy(() => import('./cms-notification'));
const CMSSms = lazy(() => import('./cms-sms'));
const CTSAbsentClasses = lazy(() => import('./cts-absent-classes'));
const PrivilegedCSR = lazy(() => import('./privillaged-csr-dashboard'));
const CustomerDropOffRegistration = lazy(() =>
  import('./customer-drop-off-registration')
);
const CsrStudentTransfer = lazy(() => import('./csr-student-transfer'));
const ApiPermissionsView = lazy(() => import('./api-permissions'));
const ApiRolePermissionsView = lazy(() => import('./api-role-permissions'));
const TopRatedInstructors = lazy(() => import('./top-rated-instructors'));
const CustomerRatingOfInstructor = lazy(() =>
  import('./customer-rating-of-instructor-new')
);
const CustomerFeedbackOfInstructor = lazy(() =>
  import('./customer-feedback-of-instructor-new')
);
const SupervisorSchedules = lazy(() => import('./supervisor-schedules'));
const BookAappointment = lazy(() => import('./book-an-appointment'));
const CustomerFeedbacks = lazy(() => import('./customer-feedback'));
const RtaTestRequest = lazy(() => import('./rta-test-request')); // newly added
const AappointmentSettigs = lazy(() => import('./appointment-settings'));
const SimulatorTrainingSchedule = lazy(() =>
  import('./simulator-training-schedule')
);
const MockTestSchedule = lazy(() => import('./mock-test-schedule'));
const InstructorIncentiveYard = lazy(() =>
  import('./instructor-incentive-yard')
);
const InstructorIncentiveRoad = lazy(() =>
  import('./instructor-incentive-road')
);
const InstructorIncentiveProcessed = lazy(() =>
  import('./instructor-incentive-processed')
);
const StudentListReport = lazy(() => import('./student-report'));
const UpgradedStudentListReport = lazy(() =>
  import('./upgraded-student-report')
);
const TestScheduleReport = lazy(() => import('./test-schedule-report'));
const AssessementScheduleReport = lazy(() =>
  import('./assessment-schedule-report')
);
const ExaminerPerformanceReport = lazy(() =>
  import('./examiner-performance-report')
);

const instructorReport = lazy(() => import('./instructor-report'));

const ScheduleConfigurations = lazy(() => import('./schedule-configurations'));
const LectureAttendanceReport = lazy(() =>
  import('./lecture-attendance-report')
);

const Reports = lazy(() => import('./reports'));
const TicketTypeAssignment = lazy(() => import('./ticket-type-assignment'));

const Tickets = lazy(() => import('./ticket'));
const KnowledgeBase = lazy(() => import('./knowledge-base'));
const KnowledgeBaseAdmin = lazy(() => import('./knowledge-base-admin'));
const TestBookingDashboard = lazy(() => import('./test_booking-dashboard'));
const OnlineLectureAttendanceReport = lazy(() =>
  import('./online-lecture-attendance-report')
);

const Corporate = lazy(() => import('./corporate'));
const InternalExaminerPassingReport = lazy(() =>
  import('./internal-examiner-passing-report')
);
const ExternalLead = lazy(() => import('./external-leads'));
const customerWaitingForTrainingSchedules = lazy(() =>
  import('./customer-waiting-training-schedules')
);
const customerWaitingForRoadTest = lazy(() =>
  import('./customer-waiting-road-test')
);

const TimeSlot = lazy(() => import('./time-slot'));

const InstructorType = lazy(() => import('./instructor-type'));
const CacheManager = lazy(() => import('./cache-manager'));
const Queue = lazy(() => import('./queue'));
const ZohoReport = lazy(() => import('./zoho-report'));
const AddOnPaymentReport = lazy(() => import('./addonpayment-report'));
const InstructorFirstAttemptPassingPerformanceReport = lazy(() =>
  import('./instructor-first-attempt-passing-performance-report')
);
const AssessorFirstAttemptPassingPerformanceReport = lazy(() =>
  import('./assessor-first-attempt-passing-performance-report')
);

const Videos = lazy(() => import('./videos'));
const Messages = lazy(() => import('./messages'));
const MessageTemplates = lazy(() => import('./message-templates'));
const PromocodeReport = lazy(() => import('./promocode-report'));
const AbsenteesimeConfigurations = lazy(() =>
  import('./absenteesim-configurations')
);
const AppVersionUpdate = lazy(() => import('./app-version-update'));
const GoldenOpportunityReport = lazy(() =>
  import('./golden-opportunity-report')
);
const WhatsappBroadcastReport = lazy(() =>
  import('./whatsapp-broadcast-report')
);

const CSRUploadRtaErrorCodes = lazy(() =>
  import('./csr-upload-rta-error-codes')
);
const StudentUtilizationReport = lazy(() =>
  import('./student-utilization-report')
);

const UploadRtaTestResults = lazy(() => import('./supervisor-rta-test-upload'));
const LumpsumMilestoneSplitup = lazy(() =>
  import('./lumpsum-milestone-splitup')
);
const CustomPaymentReport = lazy(() => import('./custom-payment-report'));
const RTAPayments = lazy(() => import('./integrator-rtaPayments'));

const vehicleMaster = lazy(() => import('./transportation/vehicles'));
const routesMaster = lazy(() => import('./transportation/routes'));
const routeAllocation = lazy(() => import('./transportation/allocation'));

class Routes extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <Suspense fallback={<ContainerLoader height={500} text="Loading..." />}>
          <Switch>
            <Route path="/auth" component={Auth} />
            <AuthenticateRoute exact path="/" component={Home} />
            <AuthenticateRoute path="/admin-users" component={AdminUser} />
            <AuthenticateRoute path="/cache" component={CacheManager} />
            <AuthenticateRoute path="/queue" component={Queue} />
            <AuthenticateRoute path="/students" component={Student} />
            <AuthenticateRoute
              path="/supervisor/students"
              component={StudentStage}
            />
            <AuthenticateRoute
              path="/supervisor/schedules"
              component={SupervisorSchedules}
            />
            <AuthenticateRoute
              path="/schedule-configurations"
              component={ScheduleConfigurations}
            />
            <AuthenticateRoute
              path="/supervisor/stage-report"
              component={StudentStage}
            />
            <AuthenticateRoute
              path="/supervisor/leave"
              component={LeaveSummary}
            />
            <AuthenticateRoute path="/yards" component={Yard} />
            <AuthenticateRoute
              path="/supervisor-instructors"
              component={Home}
            />
            <AuthenticateRoute path="/license-type" component={LicenseType} />
            <AuthenticateRoute path="/lecture-hall" component={LectureHall} />
            <AuthenticateRoute path="/fee-codes" component={FeeCode} />
            <AuthenticateRoute
              path="/csr/new-registration"
              component={CsrStudentRegistration}
            />
            <AuthenticateRoute
              path="/csr/transfer"
              component={CsrStudentTransfer}
            />
            <AuthenticateRoute
              path="/customer-new-registration"
              component={StudentRegistration}
            />
            <AuthenticateRoute
              path="/transfer-students"
              component={StudentTransfer}
            />
            <AuthenticateRoute
              path="/refund-students"
              component={StudentRefund}
            />
            <AuthenticateRoute
              path="/csr/lead-qualification"
              component={CsrLeadQualification}
            />
            <AuthenticateRoute
              path="/csr/drop-off-registration"
              component={CsrDropOffRegistration}
            />
            <AuthenticateRoute
              path="/customer-drop-off-registration"
              component={CustomerDropOffRegistration}
            />
            <AuthenticateRoute
              path="/course-rule-management"
              component={TrainingAndTestingRequirement}
            />
            <AuthenticateRoute path="/country-group" component={CountryGroup} />
            <AuthenticateRoute path="/branch-types" component={BranchType} />
            <AuthenticateRoute
              path="/low-credit-settings"
              component={LowCreditSettings}
            />
            <AuthenticateRoute path="/branches" component={Branch} />
            <AuthenticateRoute path="/instances" component={Instance} />
            <AuthenticateRoute path="/document-type" component={DocumentType} />
            <AuthenticateRoute path="/courses" component={Courses} />
            <AuthenticateRoute path="/course-types" component={CourseTypes} />
            <AuthenticateRoute
              path="/course-type-incentive"
              component={CourseTypeIncentive}
            />
            <AuthenticateRoute path="/promotion" component={Promotions} />
            <AuthenticateRoute path="/company" component={Company} />
            <AuthenticateRoute
              path="/error-code-category"
              component={ErrorCodeCategory}
            />
            <AuthenticateRoute
              path="/error-code-training"
              component={TrainingTopic}
            />
            <AuthenticateRoute path="/error-codes" component={ErrorCode} />
            <AuthenticateRoute path="/instructors" component={Instructor} />
            <AuthenticateRoute
              path="/working-hours/practical-training"
              component={WorkingHoursPracticalTraining}
            />
            <AuthenticateRoute
              path="/working-hours/theory-training"
              component={WorkingHoursTheory}
            />
            <AuthenticateRoute
              path="/working-hours/mock-test"
              component={WorkingHoursMockTest}
            />
            <AuthenticateRoute
              path="/working-hours/simulator-training"
              component={WorkingHoursSimulatorTraining}
            />
            <AuthenticateRoute
              path="/supervisor/lecture-hall-schedule"
              component={LectureHallSchedule}
            />
            <Route
              path="/supervisor/mock-test-schedule"
              component={MockTestSchedule}
            />
            <Route
              path="/supervisor/instructor-incentive-yard"
              component={InstructorIncentiveYard}
            />
            <Route
              path="/supervisor/instructor-performance-review"
              component={InstructorPerformanceReview}
            />
            <Route
              path="/supervisor/instructor-fines-upload"
              component={UploadInstructorFine}
            />
            <Route
              path="/supervisor/instructor-performance-review-report"
              component={InstructorPerformanceReviewReport}
            />
            {/* <Route 
            path="/supervisor/instructor-fines-report" 
            component={InstructorFineReport} 
            /> */}
            <Route
              path="/supervisor/instructor-incentive-road"
              component={InstructorIncentiveRoad}
            />
            <Route
              path="/supervisor/instructor-incentive-processed"
              component={InstructorIncentiveProcessed}
            />
            <Route
              path="/supervisor/simulator-training-schedule"
              component={SimulatorTrainingSchedule}
            />
            <AuthenticateRoute
              path="/lecture-theory"
              component={LectureTheory}
            />
            <AuthenticateRoute
              path="/api-access-logs"
              component={ApiAccessLogs}
            />
            <AuthenticateRoute path="/languages" component={Language} />
            <AuthenticateRoute path="/ris-languages" component={RisLanguage} />
            <AuthenticateRoute path="/tax-classes" component={TaxClass} />
            <AuthenticateRoute path="/holidays" component={Holiday} />
            <AuthenticateRoute
              path="/payment-reports"
              component={PaymentReport}
            />
            <AuthenticateRoute
              path="/integrator-traininginfo"
              component={TrainingInfo}
            />
            <AuthenticateRoute
              path="/integrator-rtapayments"
              component={RTAPayments}
            />
            <AuthenticateRoute
              path="/integrator-paymentclearance"
              component={PaymentClearance}
            />
            <AuthenticateRoute
              path="/csr/dashboard/upload-rta-error-code/:id"
              component={CSRUploadRtaErrorCodes}
            />
            <AuthenticateRoute
              path="/csr/dashboard"
              component={PrivilegedCSR}
            />
            <AuthenticateRoute
              path="/cts-studentprofileinfo"
              component={CTSStudentProfileInfo}
            />
            <AuthenticateRoute
              path="/cts-scheduleclass"
              component={CTSScheduleClass}
            />
            <AuthenticateRoute
              path="/cts-assessmentbooking"
              component={CTSAssessmentBookingInfo}
            />
            <AuthenticateRoute
              path="/ramadan-settings"
              component={RamadanSettings}
            />
            <AuthenticateRoute path="/cashier/dashboard" component={Cashier} />
            <AuthenticateRoute
              path="/cts-lectureattendanceinfo"
              component={CTSLectureAttendanceInfo}
            />
            <AuthenticateRoute
              path="/csr/collection-report"
              component={CSRCollectionReport}
            />
            <AuthenticateRoute
              path="/integrator-trainingdata"
              component={TrainingData}
            />
            <AuthenticateRoute
              path="/cts-assessmentresult"
              component={CTSAssessmentResult}
            />
            <AuthenticateRoute
              path="/cts-attendanceresult"
              component={CTSAttendanceResult}
            />
            <AuthenticateRoute
              path="/cts-instructoralert"
              component={CTSInstructorAlert}
            />
            <AuthenticateRoute path="/di-studentcdd" component={StudentCDD} />
            <AuthenticateRoute path="/di-examdetails" component={ExamDetails} />
            <AuthenticateRoute
              path="/di-reschedulewarningmessage"
              component={RescheduleWarning}
            />
            <AuthenticateRoute
              path="/integrator-dashboard"
              component={IntegratorDashboard}
            />
            <AuthenticateRoute
              path="/integrator-logs"
              component={IntegratorLog}
            />
            <AuthenticateRoute
              path="/supervisor/upload-training-info"
              component={UploadTrainingInfo}
            />
            <AuthenticateRoute
              path="/supervisor/change-instructor-of-schedules"
              component={ChangeInstructorOfSchedules}
            />
            <AuthenticateRoute
              path="/supervisor/upload-rta-test-result"
              component={UploadRtaTestResults}
            />
            <AuthenticateRoute path="/contract" component={Contract} />
            <AuthenticateRoute
              path="/cts-studentprofileinfo"
              component={CTSStudentProfileInfo}
            />
            <AuthenticateRoute
              path="/cts-scheduleclass"
              component={CTSScheduleClass}
            />
            <AuthenticateRoute
              path="/cts-assessmentbooking"
              component={CTSAssessmentBookingInfo}
            />
            <AuthenticateRoute
              path="/ramadan-settings"
              component={RamadanSettings}
            />
            <AuthenticateRoute path="/cashier/dashboard" component={Cashier} />
            <AuthenticateRoute
              path="/cts-lectureattendanceinfo"
              component={CTSLectureAttendanceInfo}
            />
            <AuthenticateRoute
              path="/csr/collection-report"
              component={CSRCollectionReport}
            />
            <AuthenticateRoute
              path="/integrator-trainingdata"
              component={TrainingData}
            />
            <AuthenticateRoute
              path="/cts-assessmentresult"
              component={CTSAssessmentResult}
            />
            <AuthenticateRoute
              path="/cts-attendanceresult"
              component={CTSAttendanceResult}
            />
            <AuthenticateRoute
              path="/cts-instructoralert"
              component={CTSInstructorAlert}
            />
            <AuthenticateRoute path="/di-studentcdd" component={StudentCDD} />
            <AuthenticateRoute path="/di-examdetails" component={ExamDetails} />
            <AuthenticateRoute
              path="/di-reschedulewarningmessage"
              component={RescheduleWarning}
            />
            <AuthenticateRoute
              path="/integrator-dashboard"
              component={IntegratorDashboard}
            />
            <AuthenticateRoute
              path="/integrator-logs"
              component={IntegratorLog}
            />
            <AuthenticateRoute path="/di-examresult" component={ExamResult} />
            <AuthenticateRoute
              path="/supervisor/upload-training-info"
              component={UploadTrainingInfo}
            />
            <AuthenticateRoute path="/contract" component={Contract} />
            <AuthenticateRoute
              path="/supervisor/dashboard"
              component={SupervisorDashboard}
            />
            <AuthenticateRoute path="/cts-sanityLog" component={SanityLog} />
            <AuthenticateRoute
              path="/cts-studentpermitdetails"
              component={CtsStudentPermitDetails}
            />
            <AuthenticateRoute
              path="/supervisor/training-schedule"
              component={TrainingSchedule}
            />
            <AuthenticateRoute
              path="/supervisor/backup-instructor-availability-generator"
              component={BackUpTrainingSchedule}
            />
            <AuthenticateRoute
              path="/supervisor/backup-instructor-schedule-report"
              component={BackUpTrainingReport}
            />
            <AuthenticateRoute
              path="/supervisor/backup-instructor-schedule-reports"
              component={BackUpTrainingNewReport}
            />
            <AuthenticateRoute
              path="/report/instructor-utilization"
              component={InstructorUtilizationReport}
            />
            <AuthenticateRoute
              path="/report/backup-instructor-utilization"
              component={BackupInstructorUtilizationReport}
            />
            <AuthenticateRoute
              path="/additional-training-log"
              component={AdditionalTrainingLog}
            />
            <AuthenticateRoute path="/sms-log" component={SmsLog} />
            <AuthenticateRoute
              path="/utilization-log"
              component={UtilizationLog}
            />
            <AuthenticateRoute
              path="/student-utilization-log"
              component={StudentwiseUtilizationLog}
            />
            <AuthenticateRoute
              path="/download-history"
              component={DownloadHistory}
            />
            <AuthenticateRoute path="/reports" component={Reports} />
            <AuthenticateRoute
              path="/report/registration"
              component={RegistrationReport}
            />
            <AuthenticateRoute path="/report/test" component={TestReport} />
            <AuthenticateRoute
              path="/report/mock/test"
              component={MockTestReport}
            />
            <AuthenticateRoute
              path="/report/instructor-api"
              component={InstructorApiReport}
            />
            <AuthenticateRoute
              path="/report/instructor-passing"
              component={InstructorPassingReport}
            />
            <AuthenticateRoute
              path="/report/instructor-passing-performance"
              component={InstructorPassingPerformanceReport}
            />
            <AuthenticateRoute
              path="/report/first-attempt-passing-performance"
              component={InstructorFirstAttemptPassingPerformanceReport}
            />
            <AuthenticateRoute
              path="/report/first-attempt-assessor-passing-performance"
              component={AssessorFirstAttemptPassingPerformanceReport}
            />
            <AuthenticateRoute path="/email-templates" component={CMSEmail} />
            <AuthenticateRoute
              path="/notification-templates"
              component={CMSNotification}
            />
            <AuthenticateRoute path="/sms-templates" component={CMSSms} />
            <AuthenticateRoute
              path="/supervisor/absent-from-cts"
              component={CTSAbsentClasses}
            />
            <AuthenticateRoute
              path="/api-role-permissions"
              component={ApiRolePermissionsView}
            />
            <AuthenticateRoute
              path="/api-permissions"
              component={ApiPermissionsView}
            />
            <AuthenticateRoute
              path="/instructor-rating/top-rated-instructors"
              component={TopRatedInstructors}
            />
            <AuthenticateRoute
              path="/instructor-rating/customer-rating-of-instructor"
              component={CustomerRatingOfInstructor}
            />
            <AuthenticateRoute
              path="/instructor-rating/customer-feedback-of-instructor"
              component={CustomerFeedbackOfInstructor}
            />
            <AuthenticateRoute
              path="/csr/appointments"
              component={BookAappointment}
            />
            <AuthenticateRoute
              path="/csr/customer-feedbacks"
              component={CustomerFeedbacks}
            />
            <AuthenticateRoute
              path="/csr/rta-test-request"
              component={RtaTestRequest}
            />
            <AuthenticateRoute
              path="/appointment-settings"
              component={AappointmentSettigs}
            />
            <AuthenticateRoute
              path="/report/student"
              component={StudentListReport}
            />
            <AuthenticateRoute
              path="/report/upgraded-students"
              component={UpgradedStudentListReport}
            />
            <AuthenticateRoute
              path="/report/test-schedule"
              component={TestScheduleReport}
            />
            <AuthenticateRoute
              path="/report/assessment-schedule"
              component={AssessementScheduleReport}
            />
            <AuthenticateRoute
              path="/report/examiner-performance-report"
              component={ExaminerPerformanceReport}
            />
            <AuthenticateRoute
              path="/report/instructor"
              component={instructorReport}
            />
            <Route path="/api-services" component={ServiceHeartBeat} />
            <Route path="/rpc-services" component={RPCHeartBeat} />
            <Route path="/corporate" component={Corporate} />
            <Route path="/corporate/report/test" component={TestReport} />
            <AuthenticateRoute
              path="/report/lecture-attendance"
              component={LectureAttendanceReport}
            />
            <AuthenticateRoute
              path="/ticket-type-assignments"
              component={TicketTypeAssignment}
            />
            <AuthenticateRoute path="/tickets" component={Tickets} />
            <AuthenticateRoute
              path="/test_booking/dashboard"
              component={TestBookingDashboard}
            />
            <AuthenticateRoute
              path="/knowledge-base"
              component={KnowledgeBase}
            />
            <AuthenticateRoute
              path="/admin/knowledge_base"
              component={KnowledgeBaseAdmin}
            />
            <AuthenticateRoute
              path="/report/online-lecture-attendance"
              component={OnlineLectureAttendanceReport}
            />
            <AuthenticateRoute
              path="/report/internal-examiner-performance"
              component={InternalExaminerPassingReport}
            />
            <AuthenticateRoute
              path="/csr/external-leads"
              component={ExternalLead}
            />
            <AuthenticateRoute path="/time-slot" component={TimeSlot} />
            <AuthenticateRoute
              path="/waiting-for-training"
              component={customerWaitingForTrainingSchedules}
            />
            <AuthenticateRoute
              path="/waiting-for-road-test"
              component={customerWaitingForRoadTest}
            />
            <AuthenticateRoute
              path="/instructor-type"
              component={InstructorType}
            />
            <AuthenticateRoute
              path="/report/zoho-report"
              component={ZohoReport}
            />
            <AuthenticateRoute
              path="/report/addonpayment-report"
              component={AddOnPaymentReport}
            />
            <AuthenticateRoute path="/videos" component={Videos} />
            <AuthenticateRoute path="/messages" component={Messages} />
            <AuthenticateRoute
              path="/message_templates"
              component={MessageTemplates}
            />
            <AuthenticateRoute
              path="/report/promocode-report"
              component={PromocodeReport}
            />
            <AuthenticateRoute
              path="/report/promocode-report/:id"
              component={PromocodeReport}
            />
            <AuthenticateRoute
              path="/absenteesim-configurations"
              component={AbsenteesimeConfigurations}
            />
            <AuthenticateRoute
              path="/app-version-update"
              component={AppVersionUpdate}
            />
            <AuthenticateRoute
              path="/report/golden-opportunity-report"
              component={GoldenOpportunityReport}
            />
            <AuthenticateRoute
              path="/report/whatsapp-broadcast-report"
              component={WhatsappBroadcastReport}
            />
            <AuthenticateRoute
              path="/report/student-utilization-report"
              component={StudentUtilizationReport}
            />
            <AuthenticateRoute
              path="/lumpsum-milestone-splitup"
              component={LumpsumMilestoneSplitup}
            />
            {/* {For Transportation} */}
            <AuthenticateRoute
              path="/transportation/vehicles"
              component={vehicleMaster}
            />
            <AuthenticateRoute
              path="/transportation/vehicle-routes"
              component={routesMaster}
            />
            <AuthenticateRoute
              path="/transportation/route-allocation"
              component={routeAllocation}
            />
            <AuthenticateRoute
              path="/report/custom-payment-report"
              component={CustomPaymentReport}
            />
            <AuthenticateRoute
              path="/csr/student-details"
              component={Student}
            />
            <AuthenticateRoute
              path="/cdd-update"
              component={CDDStudentUpdate}
            />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

export default Routes;

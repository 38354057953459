import { stringify } from 'query-string';
import axios from 'axios';
import { saveAs } from 'file-saver';
import API_DOMAIN from './apiDomain';
import { store } from '../store';

export async function getSchedules(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (request.token) {
    options.headers.Authorization = `Bearer ${request.token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }
  const url = `${API_DOMAIN}/${request.resource}`;

  const response = await axios.get(url, options);
  return {
    data: response.data,
    count: response.headers['x-total-count'],
  };
}

export async function downloadSchedules({
  instructorId,
  startDate,
  endDate,
  statuses,
}) {
  const options = {
    headers: {
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/schedule/v1/schedule/all/download?startDate=${startDate}&endDate=${endDate}`;
  if (instructorId) {
    url = `${url}&instructorId=${instructorId}`;
  }
  if (statuses && statuses.length) {
    url = `${url}&statuses=${statuses.join(',')}`;
  }

  const response = await axios.get(url, options);
  const data = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(data, `schedule-list${instructorId ? `-${instructorId}` : ''}.xlsx`);
}

export async function get(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (request.token) {
    options.headers.Authorization = `Bearer ${request.token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }
  const url = `${API_DOMAIN}/${request.resource}`;

  const response = await axios.get(url, options);
  return {
    data: response.data,
    count: response.headers['x-total-count'],
  };
}

export async function create(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (request.token) {
    options.headers.Authorization = `Bearer ${request.token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }
  const url = `${API_DOMAIN}/${request.resource}`;

  const response = await axios.post(url, data, options);
  return {
    data: response.data,
  };
}

export async function getList(request) {
  const { page = 1, perPage = 10 } = request.pagination;
  const query = {
    _start: (page - 1) * perPage,
    _end: page * perPage,
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (request.token) {
    options.headers.Authorization = `Bearer ${request.token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }
  const url = `${API_DOMAIN}/${request.resource}&${stringify(query)}`;

  const response = await axios.get(url, options);
  return {
    data: response.data,
    count: response.headers['x-total-count'],
  };
}

export async function cancelSchedule(id, token, studentId, free) {
  const url = `${API_DOMAIN}/schedule/v1/schedule/${id}?studentId=${studentId}&free=${free}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }
  return axios.delete(url, options);
}

export async function cancelMultipleSchedule(ids, token, studentId, free) {
  const url = `${API_DOMAIN}/schedule/v1/schedule/cancel-multiple`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      ids,
      studentId,
      free,
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }
  return axios.delete(url, options);
}
export async function markAsCompleted(id, token) {
  const url = `${API_DOMAIN}/schedule/v1/schedule/${id}/mark-as-completed`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }

  return axios.post(url, {}, options);
}
export async function markAsAbsent(id, token) {
  const url = `${API_DOMAIN}/schedule/v1/schedule/${id}/mark-as-absent`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }

  return axios.post(url, {}, options);
}

export async function createLectureHallSchedule(data) {
  const url = `${API_DOMAIN}/schedule/v1/lecture-hall/availability`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;

  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.post(url, data, options);
}

export async function findLectureSchedule(studentId, data) {
  const url = `${API_DOMAIN}/schedule/v1/lecture-hall/find-schedule?studentId=${studentId}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;

  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.post(url, data, options);
}
export async function bookLectureSchedule(studentId, data) {
  const url = `${API_DOMAIN}/schedule/v1/lecture-hall/book-schedule?studentId=${studentId}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;

  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.post(url, data, options);
}

export async function findMockTestSchedule(studentId, data) {
  const url = `${API_DOMAIN}/schedule/v1/mock-test/find-schedule?studentId=${studentId}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;

  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.post(url, data, options);
}

export async function bookMockTestSchedule(studentId, data) {
  const url = `${API_DOMAIN}/schedule/v1/mock-test/book-schedule?studentId=${studentId}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;

  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.post(url, data, options);
}

export async function getScheduleSummary(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (request.token) {
    options.headers.Authorization = `Bearer ${request.token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }
  const url = `${API_DOMAIN}/${request.resource}`;

  const response = await axios.get(url, options);
  return {
    data: response.data,
  };
}

export async function getDisabledSlot(instructorId, startDate) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  let url = `${API_DOMAIN}/schedule/v1/availability/instructor/disabled?instructorId=${instructorId}`;
  if (startDate) {
    url += `&startDate=${startDate}`;
  }
  const response = await axios.get(url, options);
  return {
    data: response.data,
    count: response.headers['x-total-count'],
  };
}

export async function findSimulatorDeviceSchedule(studentId, data) {
  const url = `${API_DOMAIN}/schedule/v1/simulator-training/find-schedule?studentId=${studentId}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.post(url, data, options);
}

export async function bookSimulatorDeviceSchedule(studentId, data) {
  const url = `${API_DOMAIN}/schedule/v1/simulator-training/book-schedule?studentId=${studentId}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;

  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.post(url, data, options);
}

export async function enableInstructorAvailability(data, instructorId) {
  const url = `${API_DOMAIN}/schedule/v1/availability/instructor/enable-availability?instructorId=${instructorId}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;

  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.post(url, data, options);
}

export async function changeInstructorOfSchedules(data) {
  const url = `${API_DOMAIN}/schedule/v1/schedule/instructor/change`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;

  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.post(url, data, options);
}

export async function getChangeInstructorData(data) {
  const url = `${API_DOMAIN}/schedule/v1/schedule/instructor/change-data`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;

  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.post(url, data, options);
}

export async function getVipSlots(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (request.token) {
    options.headers.Authorization = `Bearer ${request.token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }
  const url = `${API_DOMAIN}/${request.resource}`;

  const response = await axios.get(url, options);
  return {
    data: response.data,
  };
}

export async function getScheduleSettings(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (request.token) {
    options.headers.Authorization = `Bearer ${request.token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }
  const url = `${API_DOMAIN}/${request.resource}`;

  const response = await axios.get(url, options);
  return response.data;
}

export async function getCTSScheduleInfo(id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/integrator/v1/CTSScheduleClassInfo/${id}`;

  const response = await axios.get(url, options);
  return response.data;
}

export async function createCTSScheduleInfo(data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/integrator/v1/CTSScheduleClassInfo`;

  const response = await axios.post(url, data, options);
  return response.data;
}

export async function getCTSAssessmentInfo(id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/integrator/v1/CTSAssessmentBooking/${id}`;

  const response = await axios.get(url, options);
  return response.data;
}

export async function createCTSAssessmentInfo(data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/integrator/v1/CTSAssessmentBooking`;

  const response = await axios.post(url, data, options);
  return response.data;
}

export async function fetchBookingCount(data){
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/schedule/v1/availability/fetch-booking-count`;

  const response = await axios.post(url, data, options);
  return response.data;
}

export async function updateBookingCount(data){
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/schedule/v1/availability/update-booking-count`;

  const response = await axios.post(url, data, options);
  return response.data;
}

export async function sendAttendanceRequest(id, token) {
  const url = `${API_DOMAIN}/schedule/v1/schedule/check-mark-attendance-request`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  } else {
    const userToken = store.getState().authUser.token;
    if (userToken) {
      options.headers.Authorization = `Bearer ${userToken}`;
    }
  }

  return axios.post(url, {scheduleId:id}, options);
}

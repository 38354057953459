import { toastr } from 'react-redux-toastr';
import { handleCommonApiErrors } from '../api';
import {
  getNotificationList,
  markAllRead,
  getUnreadCount,
} from '../api/notification';

// action types
export const types = {
  NOTIFICATION_LIST_REQUEST: 'NOTIFICATION/LIST_REQUEST',
  NOTIFICATION_LIST_SUCCESS: 'NOTIFICATION/LIST_SUCCESS',
  NOTIFICATION_LIST_ERROR: 'NOTIFICATION/LIST_ERROR',

  NOTIFICATION_GET_UNREAD_COUNT: 'NOTIFICATION/GET_UNREAD_COUNT',

  NOTIFICATION_NOTIFY_USER: 'NOTIFICATION/NOTIFY_USER',

  NOTIFICATION_DENOTIFY_USER_REQUEST: 'NOTIFICATION/DENOTIFY_USER_REQUEST',
  NOTIFICATION_DENOTIFY_USER_SUCCESS: 'NOTIFICATION/DENOTIFY_USER_SUCCESS',
  NOTIFICATION_DENOTIFY_USER_ERROR: 'NOTIFICATION/DENOTIFY_USER_ERROR',

  NOTIFICATION_PUSH_NEW: 'NOTIFICATION/PUSH_NEW',
};

// initial state
const initialState = {
  isLoading: false,
  list: [],
  // notify: false,
  unReadCount: 0,
};

// reducer
export default (state = initialState, action) => {
  switch (action.type) {
    // list

    case types.NOTIFICATION_LIST_REQUEST: {
      const isNewList = action.currentPage === 1;
      if (isNewList) return { ...initialState, isLoading: true };
      return { ...state, isLoading: true };
    }

    case types.NOTIFICATION_LIST_SUCCESS: {
      const isNewList = action.currentPage === 1;
      return {
        ...state,
        isLoading: false,
        list: isNewList ? [...action.data] : [...state.list, ...action.data],
      };
    }

    case types.NOTIFICATION_LIST_ERROR:
      return { ...state, isLoading: false };

    // unread count

    case types.NOTIFICATION_GET_UNREAD_COUNT:
      return { ...state, unReadCount: action.data };

    // notify user

    case types.NOTIFICATION_NOTIFY_USER:
      return { ...state, unReadCount: state.unReadCount + 1 };

    // denotify

    case types.NOTIFICATION_DENOTIFY_USER_REQUEST:
      return { ...state, isLoading: true };

    case types.NOTIFICATION_DENOTIFY_USER_SUCCESS:
      return { ...state, unReadCount: 0, isLoading: false };

    case types.NOTIFICATION_DENOTIFY_USER_ERROR:
      return { ...state, isLoading: false };

    // new notifications

    case types.NOTIFICATION_PUSH_NEW:
      return {
        ...state,
        unReadCount: state.unReadCount + 1,
        list: [action.data, ...state.list],
      };

    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  getList: (request) => async (dispatch) => {
    dispatch({
      type: types.NOTIFICATION_LIST_REQUEST,
      currentPage: request.pagination.page,
    });
    try {
      const response = await getNotificationList(request);
      dispatch({
        type: types.NOTIFICATION_LIST_SUCCESS,
        data: response.data,
        currentPage: request.pagination.page,
      });
    } catch (error) {
      dispatch({ type: types.NOTIFICATION_LIST_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  getUnreadCount: () => async (dispatch) => {
    try {
      const response = await getUnreadCount();
      dispatch({
        type: types.NOTIFICATION_GET_UNREAD_COUNT,
        data: response.data.count,
      });
    } catch (error) {
      handleCommonApiErrors(error);
    }
  },

  notify: () => (dispatch) => {
    dispatch({ type: types.NOTIFICATION_NOTIFY_USER });
  },

  denotify: () => async (dispatch) => {
    dispatch({ type: types.NOTIFICATION_DENOTIFY_USER_REQUEST });
    try {
      await markAllRead();
      dispatch({ type: types.NOTIFICATION_DENOTIFY_USER_SUCCESS });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.NOTIFICATION_DENOTIFY_USER_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },
  pushNew: (item) => (dispatch) => {
    dispatch({
      type: types.NOTIFICATION_PUSH_NEW,
      data: item,
    });
  },
};

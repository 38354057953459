import { addHours } from 'date-fns';
import { actions as userActions } from './authUser';

const initialize = (store) => {
  const state = store.getState();
  const currentTime = new Date();
  if (state && state.authUser && state.authUser.token) {
    const expiry = new Date(
      state.authUser.tokenExpiry || addHours(currentTime, 1)
    );
    if (expiry.getTime() < new Date().getTime()) {
      store.dispatch(userActions.logout());
      window.location.reload();
    }
  }
};

export default initialize;

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import axios from 'axios';

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;
const messagingSenderId = process.env.REACT_APP_FIREBASE_SENDER_ID;
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
const publicVapidKey = process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY;

const firebaseConfig = {
  apiKey,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId,
  appId,
  measurementId,
};

class FirebaseManager {
  constructor(config) {
    this.config = config;
    this.messaging = null;
  }

  async initialize() {
    firebase.initializeApp(this.config);
    firebase.analytics();
    // Retrieve Firebase Messaging object.
    this.messaging = firebase.messaging();
    // Add the public key generated from the console here.
    this.messaging.usePublicVapidKey(publicVapidKey);
    await this.getNotifyPermission();
    this.messaging.onTokenRefresh(() => {
      (async () => {
        await this.getToken();
      })();
    });
  }

  async getNotifyPermission() {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      console.log('Notification permission granted.', permission);
      // TODO(developer): Retrieve an Instance ID token for use with FCM.
      const token = await this.getToken();
      if (token) {
        return {
          status: true,
          token: await this.getToken(),
        };
      }
      console.log('Generatiing token failed');
      return {
        status: false,
        token: 'empty-token',
      };
    }
    console.log('Unable to get permission to notify.');
    return {
      status: false,
      token: 'empty-token',
    };
  }

  async getToken() {
    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    try {
      const currentToken = await this.messaging.getToken();
      console.log('current token: ', currentToken);
      if (currentToken) {
        return sendTokenToServer(currentToken);
        // updateUIForPushEnabled(currentToken);
      }
      // Show permission request.
      console.log(
        'No Instance ID token available. Request permission to generate one.'
      );
      // Show permission UI.
      // updateUIForPushPermissionRequired();
      return setTokenSentToServer(false);
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
      // showToken('Error retrieving Instance ID token. ', err);
      return setTokenSentToServer(false);
    }
  }

  async generateBrowserToken() {
    const getBrowserToken = localStorage.getItem('hashed_key');
    const getServiceData = await this.callServiceEndPoint();
    let isServiceDown = true;

    if (getServiceData.status) {
      console.log('Service Connected');
      if (getServiceData.data.status) {
        const { token } = getServiceData.data;
        localStorage.setItem('hashed_key', token);
        isServiceDown = false;
        return token;
      }
      isServiceDown = true;
    }

    if (isServiceDown) {
      if (!getBrowserToken || !uuidValidate(getBrowserToken)) {
        console.log('message from browser ');
        const token = uuidv4();
        localStorage.setItem('hashed_key', token);
        return token;
      }
      return this.getBrowserToken();
    }
  }

  async callServiceEndPoint() {
    try {
      const response = await axios.get('http://localhost:9060/api/eiddevice');
      return {
        status: true,
        data: response.data,
      };
    } catch (e) {
      let errorText = 'Error, But no exception found.';
      if (e?.message) {
        errorText = e.message;
      }
      return {
        status: false,
        message: errorText,
      };
    }
  }

  getBrowserToken() {
    return localStorage.getItem('hashed_key');
  }
}

// below code will be executed only once during first import, so works as a singleton
const firebaseManager = new FirebaseManager(firebaseConfig);

export default firebaseManager;

function sendTokenToServer(token) {
  return token;
}

function setTokenSentToServer(status) {}

import axios from 'axios';

function configureResponseInterceptors({ unAuthenticatedHandler }) {
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response && error.response.status === 401) {
        unAuthenticatedHandler();
      }

      return Promise.reject(error);
    }
  );
}

export default { configureResponseInterceptors };
export { configureResponseInterceptors };

import { stringify } from 'query-string';
import axios from 'axios';
import API_DOMAIN from './apiDomain';
import { store } from '../store';

const SERVICE_URL = `${API_DOMAIN}/notification/v1/user-notification`;

export async function getNotificationList(request) {
  const { page = 1, perPage = 10 } = request.pagination;
  const query = {
    _start: (page - 1) * perPage,
    _end: page * perPage,
  };
  const userToken = store.getState().authUser.token;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
  };
  const url = `${SERVICE_URL}/messages?${stringify(query)}`;
  return axios.get(url, options);
}

export async function getUnreadCount() {
  const userToken = store.getState().authUser.token;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
  };
  const url = `${SERVICE_URL}/messages/unread-count`;
  return axios.get(url, options);
}

export async function markAllRead() {
  const userToken = store.getState().authUser.token;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
  };
  const url = `${SERVICE_URL}/messages/mark-all-read`;
  return axios.put(url, {}, options);
}

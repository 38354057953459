import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import initialize from './initializeStore';

import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// preloadedState (optional): represents initial redux state (rendered on server)
const configureStore = () => {
  const store = createStore(
    persistedReducer,
    compose(applyMiddleware(thunk, loadingBarMiddleware()))
  );

  const persistor = persistStore(store, null, () => {
    initialize(store);
  });
  return { store, persistor };
};

export default configureStore;

import { stringify } from 'query-string';
import axios from 'axios';
import { saveAs } from 'file-saver';
import API_DOMAIN from './apiDomain';
import { store } from '../store';

export async function downloadReferenceNumbers(
  { startDate, endDate, name, isActive, used },
  promoCodeId
) {
  const options = {
    headers: {
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const filter = {
    startDate,
    endDate,
    name,
    used,
    isActive,
  };
  const query = {
    _filters: JSON.stringify(filter),
    _sort: 'createdAt',
    _order: 'DESC',
    promoCodeId,
  };

  const url = `${API_DOMAIN}/pricing/v1/promotion/reference-numbers/download?${stringify(
    query
  )}`;

  const response = await axios.get(url, options);
  const data = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(data, `promotion-reference-numbers-list.xlsx`);
}

export default { downloadReferenceNumbers };

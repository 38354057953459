import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// actions
import { actions as authUserActions } from '../../store/authUser';
import { menuItems } from '../sidemenu/SideMenu';

class AuthenticateRoute extends Component {
  render() {
    const {
      component,
      isUserAuthenticated,
      permissions,
      adminUIPermissions,
      redirectSave,
      ...rest
    } = this.props;
    const uiPermissionKeys =
      adminUIPermissions && adminUIPermissions.length
        ? adminUIPermissions.map((t) => t.key)
        : [];

    let permitted = true;

    if (uiPermissionKeys.length) {
      Object.keys(menuItems).forEach((key) => {
        const menuKeys = key.split('__');
        if (`/${menuKeys[2]}`.includes(this.props.location.pathname)) {
          permitted = uiPermissionKeys.includes(menuKeys[0]);
        }
        Object.keys(menuItems[key]).forEach((childKey) => {
          const childMenuKeys = childKey.split('__');
          if (`/${childMenuKeys[0]}`.includes(this.props.location.pathname)) {
            permitted = uiPermissionKeys.includes(menuKeys[0]);
          }
        });
      });
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          // console.log('props location: ', props, props.location);
          if (!isUserAuthenticated()) {
            redirectSave(props.location.pathname);
          }
          if (
            isUserAuthenticated() &&
            !permitted &&
            props.location.pathname === '/'
          ) {
            return <this.props.component {...props} />;
          }
          if (isUserAuthenticated()) {
            return permitted ? (
              <this.props.component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  next: { from: props.location.pathname },
                }}
              />
            );
          }
          return (
            <Redirect
              to={{
                pathname: '/auth/login',
                next: { from: props.location.pathname },
              }}
            />
          );
        }}
      />
    );
  }
}

AuthenticateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({}).isRequired,
  adminUIPermissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUserAuthenticated: PropTypes.func.isRequired,
  redirectSave: PropTypes.func,
};

AuthenticateRoute.defaultProps = {
  redirectSave: () => {},
};

const mapStateToProps = (state) => ({
  isUserAuthenticated: () => state.authUser.token !== null,
  permissions: state.authUser.user ? state.authUser.user.permissions : {},
  adminUIPermissions: state.authUser.user
    ? state.authUser.user.adminUIPermissions
    : [],
});

const mapDispatchToProps = (dispatch) => ({
  redirectSave: (to) => {
    dispatch(authUserActions.redirectSave(to));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateRoute);

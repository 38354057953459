import axios from 'axios';
import API_DOMAIN from './apiDomain';
import { store } from '../store';

export async function getTimeSlots({ date, timings, resource, licenseTypeId }) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  let url = `${API_DOMAIN}/schedule/v1/work-time/time-slots?date=${date}`;
  if (timings) {
    url = `${url}&timings=${timings.join(',')}`;
  }
  if (licenseTypeId) {
    url = `${url}&licenseTypeId=${licenseTypeId}`;
  }
  if (resource) {
    url = `${url}&resource=${resource}`;
  }

  const response = await axios.get(url, options);
  return {
    data: response.data,
    count: response.headers['x-total-count'],
  };
}

export default { getTimeSlots };

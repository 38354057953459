import React from 'react';
import './styles.scss';
import loaderIcon from '../loader.gif';

class FullPageLoader extends React.Component {
  render() {
    return (
      <div className="Loader FullLoader">
        <div className="FullLoader__icon">
          <img src={loaderIcon} alt=".." />
        </div>
        {this.props.text && (
          <div className="Loader__text">{this.props.text}</div>
        )}
      </div>
    );
  }
}

export default FullPageLoader;

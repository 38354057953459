import React from 'react';
import PropTypes from 'prop-types';
import { Sentry } from '../logger';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    // // refresh on ChunLoadError, which happens after a fresh deployment as all chunks have a new hash in names now
    if (error.name === 'ChunkLoadError') {
      window.location.reload();
    } else {
      // log the error to an error reporting service
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <button
          onClick={() =>
            Sentry.showReportDialog({ eventId: this.state.eventId })
          }
        >
          Report feedback
        </button>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;

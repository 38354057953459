import React from 'react';
import './styles.scss';
import loaderIcon from '../loader.gif';

class ContainerLoader extends React.Component {
  render() {
    return (
      <div
        className="Loader ContainerLoader"
        style={{ minHeight: this.props.height || 50 }}
      >
        {' '}
        <div className="ContainerLoader__icon">
          <img src={loaderIcon} alt=".." />
        </div>
        {this.props.text && (
          <div className="Loader__text">{this.props.text}</div>
        )}
      </div>
    );
  }
}

export default ContainerLoader;

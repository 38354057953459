import React from 'react';
import ReactDOM from 'react-dom';

import { store, persistor } from './store';
import Root from './containers/Root';
import { startErrorLogging } from './logger';
import notificationSocket from './notification-socket';
import { configureResponseInterceptors } from './axios-config';
import { types as authTypes } from './store/authUser';

// TODO: required when going with web push notification
import firebaseManager from './firebaseSetup';

firebaseManager.generateBrowserToken();
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('../firebase-messaging-sw.js')
//     .then(function(registration) {
//       console.log('Registration successful, scope is:', registration.scope);
//     })
//     .catch(function(err) {
//       console.log('Service worker registration failed, error:', err);
//     });
// }

// configure api req/res handlers
configureResponseInterceptors({
  unAuthenticatedHandler: () => {
    store.dispatch({ type: authTypes.LOGOUT });
  },
});
// enable remote logging
startErrorLogging();

// establish socket connection for notification
notificationSocket.connect();

ReactDOM.render(
  <Root store={store} persistor={persistor} />,
  document.getElementById('root')
);

export default ({ validators = [], fieldsToValidate = {} }) => {
  let areFieldsValid = true;
  const fields = { ...fieldsToValidate };

  // console.log('validators: ', validators);
  // console.log('fields:', fields);

  Object.keys(fields).forEach((fieldKey) => {
    // avoid validation when not shown
    if (fields[fieldKey].show) {
      const shouldShow = fields[fieldKey].show(fields);
      if (!shouldShow) return;
    }

    let fieldValidators = null;
    if (validators.length) {
      fieldValidators = validators;
    } else {
      fieldValidators = fields[fieldKey].validators || [];
    }
    for (let i = 0; i < fieldValidators.length; i += 1) {
      const validatorMessage = fieldValidators[i]({
        field: fieldKey,
        value: fields[fieldKey].value,
        label: fields[fieldKey].label,
        length: fields[fieldKey].length,
        fields,
        matchingField: fields[fieldKey].matchingField,
        name: fields[fieldKey].name,
      });
      fields[fieldKey].error = validatorMessage;

      if (validatorMessage.length) {
        areFieldsValid = false;
        break;
      }
    }
  });

  // this.setState((prevState) => ({
  //   fields: { ...prevState.fields, ...fields },
  // }));
  return { areFieldsValid, updatedFields: fields };
};
